:root {
    --blue-color: #0C406F;
    --gold-color: #DCA023;
    --white-color: #FFFFFF;
    --neutral-color: #12171B;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  
    .card {
      background-size: cover;
      border-radius: 0.5rem;
      color: var(--white-color);
      max-width: 50ch;
      overflow: hidden;
      padding: 10rem 0 0;
      transition: transform 500ms ease;
      font-family:sans-serif;
  
      &__content {
        --padding: 0.1rem 1.5rem 1.5rem;
        background: var(--blue-color);
        padding: var(--padding);
        height: 200px;


      }
  
      &__title {
        position: relative;
        width: max-content;
      }
  
      &__body {
        color: white;
        text-align: left;
        margin-top: -5px;

      }
      
      &__button {
        align-items: center;
        margin-top: 7px;
        gap: 10px;
        background: none;
        border-style: none;
        align-self: flex-end;
        color: white;
        cursor: pointer;
        display: flex;
        padding: .5em 1.25em;
        text-decoration: none;
        font-size: 14px;
        font-weight:600;
        
        &:hover,
        &:focus {
          //background-color: var(--white-color);
        }
      }
    }
  
    @media (hover) {
      .card__content {
        transform: translateY(72%);
        transition: transform 500ms ease;
  
        > *:not(.card__title) {
          opacity: 0;
          transition: opacity 500ms linear;
        }
      }
  
      .card {
        &:hover,
        &:focus-within {
          transform: scale(1.05);
          
    
          .card__content {
            background-color: var(--gold-color);
            transform: translateY(0);
            transition-delay: 100ms;

    
            > *:not(.card__title) {
              opacity: 1;
              transition-delay: 100ms;
            }
          }
    
          .card__title:after {
            transform: scaleX(1);
          }
        }
  
        &:focus-within .card__content {
          transition-duration: 0ms;
      
          > *:not(.card__title) {
            transition-delay: 300ms;
          }
        }
  
        &__title:after {
          transform: scaleX(0);
        }
      }
    }
  
    @media (prefers-reduced-motion: reduce) {
      *,
      *::before,
      *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        scroll-behavior: auto !important;
        transition-delay: 0ms !important;
        transition-duration: 0.01ms !important;
      }
    }
  