  
  img {
    height: 45px;
    padding: 0 50px;
    object-fit:contain;
  }
  
  img:last-of-type {
    padding-left: 0;
  }
  
  .inner {
    position: relative;
    width: 100vw;
    overflow: hidden;
    height: 120px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;

    justify-content: center;
    align-items: center;
    background-color: #DCA023;





  }
  
  .wrapper {
    position: absolute;
    display: flex;
    width: 100vw;
    max-width: 1920px;
    overflow: hidden;

    


  }
  
  section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
    align-items: center;
    justify-content: center;

  }
  
  @keyframes swipe {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-100%);
    }
  }
  