

.container {
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
}

.container img {
    overflow: hidden;
    object-fit: cover;
    height: 100%;
    width: 100%;
    padding-right: 0;
    border-radius: 5px;
}